@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

* html{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Italiana', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gradient-bg-gg {
  font-family: 'Luxurious Roman', cursive;
  background-color: #030406;
  background-image: radial-gradient(
      at 0% 0%,
      hsl(0, 0%, 0%) 0%,
      transparent 50%
    ),
    radial-gradient(at 50% 0%, hsl(231, 78%, 5%) 0, transparent 50%),
    radial-gradient(at 100% 0%, hsl(231, 78%, 5%) 0, transparent 50%);
}

.column {
  float: left;
  width: 33.33%;
  padding: 5px;
}

.gradient-bg-artworks {
  background-color: #0f0e13;
  background-image: radial-gradient(
      at 50% 50%,
      hsl(302deg 25% 18%) 0,
      transparent 50%
    ),
    radial-gradient(at 0% 0%, hsla(253, 16%, 7%, 1) 0, transparent 50%),
    radial-gradient(at 50% 50%, rgb(151, 56, 35) 0, transparent 50%);
}

.gradient-bg-footer {
  background-color: #131835;
  background-image: radial-gradient(
      at 20% 100%,
      hsl(333deg 85% 53%) 0,
      transparent 40%
    ),
    radial-gradient(at 50% 120%, hsla(339, 49%, 30%, 1) 0, transparent 40%);
}

.text-gradient {
  background: -webkit-linear-gradient(#296cb8df, #0d0b0b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.label-gradient {
  background: rgb(19, 24, 53);
  background: linear-gradient(
    31deg,
    rgba(19, 24, 53, 1) 0%,
    rgba(237, 33, 124, 0) 100%
  );
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.lds-dual-ring {
  display: inline-block;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}


@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}





@media screen and (max-width: 736px) {

  /* Use rem units for font sizes */
  img {
    font-size: 1rem;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  video {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  /* Simplify the layout and remove unnecessary elements */
  .column {
    width: 100%;
  }

  /* Adjust margins and padding */
  .row {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .para {
    width: auto;
    margin: 10px 0;
    padding: 0;
  }

  /* Use mobile-first approach */
  .box {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
  }
}

  

  
  
  

@tailwind base;
@tailwind components;
@tailwind utilities;